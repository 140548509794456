<template>
    <v-app>
        <v-main class="root">
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",
};
</script>
<style>
html {
    overflow: auto !important;
}
.root{
    background: #0A0A15;
}
</style>
