import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    videoDevices: [],
    audioDevices: [],
    currentVideoDeviceId: null,
    currentAudioDeviceId: null,

    user: null,
    streamTitle: null,
    streamStartDate: null,
    streamLogo: null,
    hostId: null,
    agoraAccessToken: null
  },
  mutations: {
    SET_VIDEO_DEVICES(state, payload) {
      state.videoDevices = payload
    },
    SET_AUDIO_DEVICES(state, payload) {
      state.audioDevices = payload
    },
    SET_CURRENT_VIDEO_DEVICE_ID(state, payload) {
      state.currentVideoDeviceId = payload
    },
    SET_CURRENT_AUDIO_DEVICE_ID(state, payload) {
      state.currentAudioDeviceId = payload
    },
    SET_STREAM_TITLE(state, payload) {
      state.streamTitle = payload
    },
    SET_STREAM_START_DATE(state, payload) {
      state.streamStartDate = payload
    },
    SET_HOST_ID(state, payload) {
      state.hostId = payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_AGORA_ACCESS_TOKEN(state, payload) {
      state.agoraAccessToken = payload
    },
    SET_STREAM_LOGO(state, payload) {
      state.streamLogo = payload
    }
  },
  actions: {
    setVideoDevices({ commit }, payload) {
      commit('SET_VIDEO_DEVICES', payload);
    },
    setAudioDevices({ commit }, payload) {
      commit('SET_AUDIO_DEVICES', payload);
    },
    setCurrentVideoDeviceId({ commit }, payload) {
      commit('SET_CURRENT_VIDEO_DEVICE_ID', payload);
    },
    setCurrentAudioDeviceId({ commit }, payload) {
      commit('SET_CURRENT_AUDIO_DEVICE_ID', payload);
    },
    async initLiveStream({ commit }, payload) {
      try {
        const { data } = await axios.post(process.env.VUE_APP_ROOT_API + '/live/init', payload);
        if (data.success) {
          commit('SET_USER', data.data.user);
          commit('SET_STREAM_TITLE', data.data.ticket.name);
          commit('SET_STREAM_START_DATE', data.data.ticket.event.starts_at);
          commit('SET_HOST_ID', data.data.host_id);
          commit('SET_AGORA_ACCESS_TOKEN', data.data.token);
          return true
        }
        return false
      } catch (error) {
        return false
      }
    },
    async initInviteLiveStream({ commit }, payload) {
      try {
        const { data } = await axios.post(process.env.VUE_APP_ROOT_API + '/live/invite', payload);
        if (data.success) {
          commit('SET_USER', data.data.user);
          commit('SET_STREAM_TITLE', data.data.ticket.name);
          commit('SET_STREAM_START_DATE', data.data.ticket.event.starts_at);
          commit('SET_STREAM_LOGO', data.data.ticket.event.image);
          commit('SET_HOST_ID', data.data.host_id);
          commit('SET_AGORA_ACCESS_TOKEN', data.data.token);
          return true
        }
        return false
      } catch (error) {
        return false
      }
    }
  },
  modules: {}
});
