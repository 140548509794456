<template>
    <v-footer app bottom fixed color="#1C1D2D" dark>
        <div class="stream-menus">
            <v-btn class="stream-btn audio" min-width="60" width="60" height="40" color="#2D2E40" @click="audioDeviceControl">
                <v-icon v-if="audioState">mdi-microphone</v-icon>
                <v-icon v-else color="red">mdi-microphone-off</v-icon>
            </v-btn>
            <v-btn class="stream-btn camera" min-width="60" width="60" height="40" color="#2D2E40" @click="videoDeviceControl">
                <v-icon v-if="cameraState">mdi-video</v-icon>
                <v-icon v-else color="red">mdi-video-off</v-icon>
            </v-btn>
            <v-btn class="stream-btn record" min-width="60" width="60" height="40" :color="recordingState? 'red' : '#2D2E40'" @click="handleRecord()">
                <v-icon>mdi-radiobox-marked</v-icon>
            </v-btn>
            <!-- <v-btn class="stream-btn share" min-width="60" width="60" height="40" color="#2D2E40">
                <label></label>
            </v-btn> -->
        </div>
        <v-spacer></v-spacer>
        <v-btn class="stream-btn end-stream" color="#FF4141" height="40" @click="endStream">End Stream</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="stream-btn expand" min-width="60" width="60" height="40" color="#2D2E40" @click="settingModalControl(true)">
            <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        <v-btn class="stream-btn expand" min-width="60" width="60" height="40" color="#2D2E40" @click="toggleFullScreen">
            <v-icon>mdi-arrow-expand</v-icon>
        </v-btn>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    props: {
        cameraControl:{
            type: Function,
            default: () => {}
        },
        audioControl:{
            type: Function,
            default: () => {}
        },
        settingModalControl: {
            type: Function,
            default: () => {}
        },
        recordStart: {
            type: Function,
            default: () => {}
        },
        recordStop: {
            type: Function,
            default: () => {}
        },
        recordingState: {
            type: Boolean,
            default: false
        },
        endStream: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            cameraState: true,
            audioState: true,
        }
    },
    methods: {
        videoDeviceControl(){
            this.cameraControl(!this.cameraState);
            this.cameraState = !this.cameraState;
        },
        audioDeviceControl(){
            this.audioControl(!this.audioState);
            this.audioState = !this.audioState;
        },
        toggleFullScreen(){
            const document = window.document
            const fs = document.getElementById('app')
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
                if (fs.requestFullscreen) {
                    fs.requestFullscreen()
                } else if (fs.msRequestFullscreen) {
                    fs.msRequestFullscreen()
                } else if (fs.mozRequestFullScreen) {
                    fs.mozRequestFullScreen()
                } else if (fs.webkitRequestFullscreen) {
                    fs.webkitRequestFullscreen()
                }
            } else if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            }
        },
        handleRecord(){
            this.recordingState ? this.recordStop() : this.recordStart();
        }
    },
};
</script>
<style scoped>
.stream-menus .share label {
    background: url(/img/share.svg);
}
.end-stream{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
