<template>
    <v-container class="join-layout-container">
        <v-row class="join-layout">
            <v-col cols="12" sm="12" md="6" xl="6" class="relative">
                <div v-if="videoActive" id="previewScreen"/>
                <div v-else class="no-video-layout"></div>
                <div class="statusIcons">
                    <div v-if="!videoActive" class="camIcon">
                        <v-icon>mdi-video-off</v-icon>
                    </div>
                    <div class="micIcon">
                        <v-icon v-if="!audioActive"> mdi-microphone-off </v-icon>
                        <v-icon v-else color="#fb0"> mdi-microphone </v-icon>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" xl="6">
                <h2>
                    Provide access to
                    <br />Microphone and Camera
                </h2>
                <p>
                    To ensure the correct operation of the service,
                    <br />you need provide access to the microphone and camera. <br />Before entering the call, <br />make sure you can see and hear yourself.
                </p>
                <div style="margin-top: 20px">
                    <v-select solo background-color="#2D2E40" dark item-text="label" :items="videoDevices" :value="videoDevices.length > 0 && videoDevices[currentVideoDeviceIndex].label" @change="changeVideoDevice" />
                    <v-select solo background-color="#2D2E40" dark item-text="label" :items="audioDevices" :value="audioDevices.length > 0 && audioDevices[currentAudioDeviceIndex].label" @change="changeAudioDevice" />
                </div>
                <div class="t-center">
                    <v-btn height="40" width="90" color="#fb0" class="join-btn" @click="joinStream"> Join </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng";
export default {
    name: "Join",
    props: {
        joinStream: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            videoDevices: [],
            audioDevices: [],
            currentVideoDeviceIndex: null,
            currentAudioDeviceIndex: null,
            videoActive: true,
            audioActive: true,
            DevicePermissionState: {
                DENIED: "denied",
                GRANTED: "granted",
                PROMPT: "prompt",
            },
            currentVideoTrack: null,
        };
    },
    async mounted() {
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        try {
            const audioPermission = await navigator.permissions.query({ name: "microphone" });
            if (audioPermission.state === this.DevicePermissionState.GRANTED) {
                this.audioActive = true;
            } else {
                this.audioActive = false;
            }
        } catch (error) {
            this.audioActive = false;
        }
        try {
            const videoPermission = await navigator.permissions.query({ name: "camera" });
            if (videoPermission.state === this.DevicePermissionState.GRANTED) {
                this.videoActive = true;
            } else {
                this.videoActive = false;
            }
        } catch (error) {
            this.videoActive = false;
        }
        AgoraRTC.getDevices()
            .then((devices) => {
                const audioDevices = devices.filter(function (device) {
                    return device.kind === "audioinput";
                });
                if (audioDevices.length > 0) {
                    this.$store.dispatch("setAudioDevices", audioDevices);
                    this.audioDevices = audioDevices;
                    this.currentAudioDeviceIndex = 0;
                    this.$store.dispatch("setCurrentAudioDeviceId", this.currentAudioDeviceIndex);
                }
                const videoDevices = devices.filter(function (device) {
                    return device.kind === "videoinput";
                });
                if (videoDevices.length > 0) {
                    this.$store.dispatch("setVideoDevices", videoDevices);
                    this.videoDevices = videoDevices;
                    this.currentVideoDeviceIndex = 0;
                    this.$store.dispatch("setCurrentVideoDeviceId", this.currentVideoDeviceIndex);
                }
                return Promise.resolve(AgoraRTC.createCameraVideoTrack({ cameraId: videoDevices[this.currentVideoDeviceIndex].deviceId }));
            })
            .then((videoTrack) => {
                videoTrack.play("previewScreen");
                this.currentVideoTrack = videoTrack
            })
            .catch(error =>{
                console.log(error)
            })
    },
    methods: {
        changeVideoDevice(val) {
            this.currentVideoDeviceIndex = this.videoDevices.findIndex(item => item.label === val)
            this.$store.dispatch("setCurrentVideoDeviceId", this.currentVideoDeviceIndex);
            AgoraRTC.createCameraVideoTrack({ cameraId: this.videoDevices[this.currentVideoDeviceIndex].deviceId }).then(videoTrack =>{
                this.currentVideoTrack.close();
                videoTrack.play("previewScreen");
                this.currentVideoTrack = videoTrack
            })
        },
        changeAudioDevice(val) {
            this.currentAudioDeviceIndex = this.audioDevices.findIndex(item => item.label === val)
            this.$store.dispatch("setCurrentAudioDeviceId", this.currentAudioDeviceIndex);
        }
    },
};
</script>

<style scoped>
.join-layout-container {
    height: 100%;
}
.join-layout {
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
}
.no-video-layout {
    background: #1c1d2d;
    width: 100%;
    height: 400px;
    border-radius: 15px;
}
.join-layout p {
    margin-top: 20px;
    font-weight: 300;
    color: #9fa0be;
}
.join-btn {
    color: white;
    text-transform: none;
}
#previewScreen{
    height: 400px;
}
.statusIcons {
    width: 40px;
    height: fit-content;
    position: absolute;
    border-radius: 6px;
    left: 23px;
    bottom: 23px;
}
.statusIcons i {
    color: #fb0;
}
.relative{
    position: relative;
}
</style>
