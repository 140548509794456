<template>
    <v-app-bar app color="#1C1D2D" dark>
        <div class="stream-title">
            <h3 class="stream">
                {{ streamTitle }}
            </h3>
            <span v-if="date">
                {{ date }} (EST)
            </span>
        </div>
        <v-spacer></v-spacer>
        <div class="stream-mode" v-if="!joinStreamPageShow">
            <v-btn class="stream-btn play" min-width="60" width="60" height="40" color="#2D2E40">
                <label></label>
            </v-btn>
            <v-btn class="stream-btn board" min-width="60" width="60" height="40" color="#2D2E40">
                <label></label>
            </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div class="stream-action" v-if="!joinStreamPageShow">
            <!-- <v-btn class="stream-btn play" min-width="60" width="60" height="40" color="#2D2E40">
                <v-icon>mdi-play</v-icon>
            </v-btn> -->
            <!-- <v-btn class="stream-btn stop" min-width="60" width="60" height="40" color="#2D2E40">
                <v-icon>mdi-stop</v-icon>
            </v-btn> -->
        </div>
    </v-app-bar>
</template>

<script>
const moment = require('moment-timezone')
export default {
    name: "Topbar",
    props: {
        joinStreamPageShow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            streamTitle: null,
            date: null,
        };
    },
    watch: {
        "$store.state.streamTitle": {
            immediate: true,
            handler() {
                this.streamTitle = this.$store.state.streamTitle;
            },
        },
        "$store.state.streamStartDate": {
            immediate: true,
            handler() {
                this.date = this.$store.state.streamStartDate ? moment.utc(this.$store.state.streamStartDate).tz(moment.tz.guess(true)).format('dddd, D MMMM, HH:mm') : '';
            },
        },
    },
};
</script>
<style>
.stream-btn label {
    background-repeat: no-repeat !important;
    width: 40px !important;
    height: 40px !important;
    cursor: pointer !important;
    background-position: center !important;
}
.stream-btn {
    border-radius: 10px !important;
    margin-right: 10px !important;
    text-transform: none !important;
}
</style>
<style scoped>
.stream-title span {
    font-size: 12px;
    color: #c7c7cd;
}
.stream-mode {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.stream-mode .play label {
    background: url(/img/play-hover.svg);
}
.stream-mode .board label {
    background: url(/img/board.svg);
}
</style>
