<template>
  <div>
      
  </div>
</template>

<script>
export default {
    name: 'Intro'
}
</script>

<style>

</style>