<template>
    <div>
        <h3>Your Cameras</h3>
        <div id="cameraList" style="overflow:auto"></div>
    </div>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng";
export default {
    name: "CameraList",
    props: {
        switchCamera: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            videoDevices: [],
        };
    },
    mounted() {
        this.videoDevices = this.$store.state.videoDevices;
        this.videoDevices.map(async (device, index) => {
            const cameraTrack = await AgoraRTC.createCameraVideoTrack({ cameraId: device.deviceId });
            const cameraTrackContainer = document.createElement("div");
            cameraTrackContainer.className = "camera-track"
            const id = "camera-" + device.deviceId;
            cameraTrackContainer.id = id;
            cameraTrackContainer.addEventListener("click", this.changeCamera.bind(null, {
                deviceId: device.deviceId,
                index
            }), false);
            document.getElementById("cameraList").append(cameraTrackContainer);
            cameraTrack.play(id);
            this.updateCameraHeight()
        });
        window.addEventListener("resize", this.updateCameraHeight);
    },
    methods: {
        updateCameraHeight: () => {
            var elements = document.getElementsByClassName('camera-track');
            if (elements.length > 0) {
                let width = elements[0].offsetWidth;
                let height = width / 16 * 10;
                for (var i = 0; i < elements.length; i++ ) {
                    elements[i].style.height = height + 'px'
                }
            }
            document.getElementById('cameraList').style.height = document.getElementById('layout').offsetHeight - 100 + 'px';
        },
        changeCamera(payload){
            this.switchCamera(payload?.deviceId);
            this.$store.dispatch("setCurrentVideoDeviceId", payload?.index);
        }
    },
};
</script>
<style scoped>
h3{
    color: white;
    margin-bottom: 20px;
}
</style>
<style>
.camera-track{
    margin-bottom: 20px;
    cursor: pointer;
}
.camera-track:hover{
    opacity: 0.6;
}
#cameraList{
    height: 400px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
