<template>
    <div v-if="loading" class="loading-logo">
        <div class="loading-text">
            <img src="/img/logo-white.png" alt="logo" />
            <v-progress-circular class="spinner" indeterminate size="45" width="3.3" color="#fb0" />
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props:{
        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped>
.loading-logo {
    width: 100%;
    height: 100%;
    background: #14171d;
    opacity: 0.8;
    position: fixed;
    z-index: 99999999;
}
.loading-logo .loading-text {
    color: white;
    top: 40%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
.loading-logo .loading-text span {
    margin-left: 10px;
    font-size: 14pt;
}
.loading-logo .loading-text img {
    height: 55px;
}
.loading-logo .loading-text .spinner {
    margin-top: -40px;
    margin-left: 10px;
}
</style>
