import Vue from "vue";
import VueRouter from "vue-router";
import LiveStream from "../views/live/LiveStream.vue";
import PageNotFound from "../views/PageNotFound.vue";
import Page422 from "../views/422.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/live/:id",
    name: "live",
    component: LiveStream
  },
  {
    path: '/422',
    component: Page422
  },
  {
    path: '*',
    component: PageNotFound
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
